<template>
  <div v-if="gcInvite_inviteInfo.invitee_user_image_enable" id="upload-component" class="pb-5">
    <v-divider class="mt-1 mb-6" />

    <!--  텍스트: 안내문구  -->
    <p class="text-body-2 font-weight-bold mb-6 blue--text">
      - 이미지를 첨부해주세요.(최대 12장) <br>
    </p>

    <!--  레퍼: 그리드 레이아웃  -->
    <div class="c-grid-container mb-4">
      <!--  업로드 리스트 아이템  -->
      <v-card
        v-for="(objUrl, i) in objectURLs"
        :key="`img-${i}`"
        :class="`c-img-card pa-1 ${isCheckImg(i) ? 'checked' : ''}`"
        :disabled="gcInvite_submitting"
        outlined
        @click="onClickImgOpen(objUrl, i)">
        <!--  이미지  -->
        <v-img :src="objUrl" aspect-ratio="1" contain width="100%">
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="grey" indeterminate />
            </v-row>
          </template>
        </v-img>

        <!--  아이콘: 아이템 삭제 예정 표기  -->
        <v-icon v-if="removeMode" :color="isCheckImg(i) ? 'red' : ''" class="c-check-icon" large>
          {{ isCheckImg(i) ? 'mdi-checkbox-marked-circle' : 'mdi-circle-outline' }}
        </v-icon>
      </v-card>
    </div>

    <!--  인풋: 멀티 이미지 파일 업로드  -->
    <input ref="input" accept="image/jpeg,image/png,image/webp" hidden multiple type="file" @change="onInputFiles">

    <!--  버튼 그룹: 삭제 모드  -->
    <template v-if="removeMode">
      <!--  버튼: 삭제 취소  -->
      <v-btn :disabled="gcInvite_submitting" block outlined @click="onClickCancel">
        <v-icon>mdi-close</v-icon>
        <span class="text-h6 font-weight-bold">취소</span>
      </v-btn>
      <!--  버튼: 삭제 적용  -->
      <v-btn :dark="enabledApplyButton" :disabled="!enabledApplyButton" block class="mt-4" color="green" depressed
             @click="onClickRemove">
        <v-icon>mdi-check</v-icon>
        <span class="text-h6 font-weight-bold">적용</span>
      </v-btn>
    </template>

    <!--  버튼 그룹: 업로드 모드  -->
    <template v-else>
      <!--  버튼: 이미지 추가  -->
      <v-btn :disabled="!enabledAddButton" block color="primary" depressed @click="onClickAddType">
        <v-icon>mdi-plus</v-icon>
        <span class="text-h6 font-weight-bold">
          {{ gcInvite_inviteInfo.invitee_user_image_label || '이미지 업로드' }}
        </span>
      </v-btn>
      <!--  버튼: 삭제 모드 변경  -->
      <v-btn :disabled="!enabledRemoveButton" block class="mt-4" color="red" outlined @click="removeMode = true">
        <v-icon>mdi-trash-can</v-icon>
        <span class="text-h6 font-weight-bold">삭제</span>
      </v-btn>
    </template>

    <!--  다이알로그: 이미지 상세보기  -->
    <v-dialog v-model="dialogShow" fullscreen hide-overlay persistent scrollable>
      <v-card tile>

        <!--  그룹: 컨트롤러  -->
        <v-card-actions class="px-1">
          <!--  버튼: 뒤로가기  -->
          <v-btn class="mr-7" icon @click="onClickImgClose">
            <v-icon x-large>mdi-chevron-left</v-icon>
          </v-btn>

          <v-spacer />

          <!--  버튼: 삭제  -->
          <v-btn color="red" text @click="onClickRemoveOne">
            삭제
          </v-btn>
        </v-card-actions>

        <v-divider class="grey" />

        <v-card-text class="pa-2 white-space-pre-wrap grey lighten-3">
          <v-img v-if="imgDialogData.objUrl" :src="imgDialogData.objUrl" contain height="100%" width="100%" />
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const MAX_LIST_LENGTH = 12

export default {
  name: 'SubmitCardFormUpload',

  data: () => ({
    dialogShow: false,
    removeMode: false,

    objectURLs: [],
    // 제거할 인덱스 리스트 ( 기준이 되는 배열: imgList )
    toRemoveIndex: [],

    // 이미지 상세보기에 필요한 객체정보
    imgDialogData: {
      objUrl: '',
      index: -1 // imgList 에 해당하는 인덱스
    }
  }),

  computed: {
    imgLength() {
      return this.$store.state.gsInvite_formData.invitee_user_image.length
    },

    // 삭제 적용 버튼 클릭
    enabledApplyButton() {
      return !!this.toRemoveIndex.length && !this.gcInvite_submitting
    },

    // 업로드 버튼 노출 여부
    enabledAddButton() {
      const notRemoveMode = !this.removeMode
      const notMaximum = 0 < MAX_LIST_LENGTH - this.imgLength

      return notRemoveMode && notMaximum && !this.gcInvite_submitting
    },

    // 삭제 버튼 노출 여부
    enabledRemoveButton() {
      return !!this.imgLength && !this.gcInvite_submitting
    }
  },

  methods: {
    // 삭제하기 위해 채크된 이미지인지 여부
    isCheckImg(i) {
      return this.toRemoveIndex.indexOf(i) !== -1
    },

    // 이미지 클릭시
    onClickImgOpen(objUrl, i) {
      // 삭제 모드 일 경우
      if (this.removeMode) {
        const target = this.toRemoveIndex.indexOf(i)

        // 리스트에 없는 경우 추가, 있는경우 제거
        target === -1 ?
          this.toRemoveIndex.push(i) : this.toRemoveIndex.splice(target, 1)
      }
      // 일반 클릭일 경우
      else {
        this.dialogShow = true
        this.imgDialogData.objUrl = objUrl
        this.imgDialogData.index = i
      }
    },

    // 이미지 상세보기 팝업 닫기 시
    onClickImgClose() {
      this.dialogShow = false
      this.imgDialogData.objUrl = ''
      this.imgDialogData.index = -1
    },

    // 파일 업로드 타입 버튼 클릭
    onClickAddType() {
      this.$refs.input.click()
    },

    // 파일 업로드 이벤트 핸들러
    onInputFiles(event) {
      // 최대로 업로드 가능한 파일 개수
      const limit = MAX_LIST_LENGTH - this.imgLength
      const files = [ ...event.target.files ]

      // 업로드 파일이 없을 경우 동작하지 않고 리턴
      if (!files.length) return

      // 허용 가능한 업로드 개수보다 파일리스트가 클 경우 넘치는 부분 제거
      limit < files.length && files.splice(limit)

      this.$store.state.gsInvite_formData.invitee_user_image.push(...files)
      this.objectURLs.push(...files.map(file => URL.createObjectURL(file)))
    },

    // 삭제 모드 취소
    onClickCancel() {
      this.toRemoveIndex.splice(0)
      this.removeMode = false
    },

    // 삭제 진행
    onClickRemove() {
      const store = this.$store.state
      const imgList = [ ...store.gsInvite_formData.invitee_user_image ]
      // 내림차순 정렬해서 뒤에서부터 삭제해야 인덱스 밀림 이슈 없음
      const toRemoveIndex = [ ...this.toRemoveIndex ].sort((a, b) => b - a)

      toRemoveIndex.forEach(i => imgList.splice(i, 1))
      store.gsInvite_formData.invitee_user_image = imgList
      this.objectURLs = imgList.map(file => URL.createObjectURL(file))

      // 지우려고 했던 대기열 값 모두 삭제
      this.toRemoveIndex.splice(0)
      this.removeMode = false
    },

    // 단일 삭제 버튼 클릭 시
    onClickRemoveOne() {
      const it = this
      const callback = function() {
        it.toRemoveIndex.push(it.imgDialogData.index)
        it.onClickImgClose()

        // 이미지 다이알로그 닫힌 후 삭제 진행
        setTimeout(() => {
          it.onClickRemove()
        })
      }

      this.gmCommon_alertOpen({
        text: '현재 이미지를 삭제할까요?',
        confirmOkText: '삭제',
        confirmOkColor: 'red',
        confirmNoColor: 'default',
        confirm: true,
        callback
      })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../sass/variables"

.c-grid-container
  display: grid
  grid-gap: 8px
  grid-template-columns: repeat(3, 1fr)
  @media (min-width: 420px)
    grid-template-columns: repeat(4, 1fr)
  @media (min-width: 640px)
    grid-template-columns: repeat(5, 1fr)

  > *
    aspect-ratio: 1
  // 참고: 최신 속성이므로 광범위하게 적용 안될수도 있음
  .c-img-card
    outline-width: 1px
    outline-style: dashed
    outline-color: map-get($shades, 'black')

    &.checked
      outline-style: solid
      outline-color: map-get($red, 'accent-1')

    .c-check-icon
      position: absolute
      top: 0
      right: 0
      border-radius: 50% !important
      background-color: rgba(255, 255, 255, .75)

.c-gap
  gap: 20px
</style>
