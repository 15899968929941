export default {
  gmInvite_initForm(state) {
    const id = state.gsInvite_inviteInfo.id
    const form = state.gsInvite_formData

    form.invitee_name = ''
    form.invitee_company = ''
    form.invitee_part = ''
    form.invitee_phone = ''
    form.invitee_email = ''
    form.invitee_stay = ''
    form.invitee_code = ''
    form.invitee_vendor_code = ''
    form.invitee_terms = false
    form.invitee_custom_field = []
    form.invitee_user_image = []

    const part1_id = localStorage.getItem(`${id}_sales_part1`)
    form.sales_part1 = part1_id !== null ? Number( part1_id ) : -1
    form.sales_part1_typed =
      localStorage.getItem(`${id}_sales_part1_typed`) || ''

    const part2_id = localStorage.getItem(`${id}_sales_part2`)
    form.sales_part2 = part2_id !== null ? Number( part2_id ) : -1
    form.sales_part2_typed =
      localStorage.getItem(`${id}_sales_part2_typed`) || ''

    const part3_id = localStorage.getItem(`${id}_sales_part3`)
    form.sales_part3 = part3_id !== null ? Number( part3_id ) : -1
    form.sales_part3_typed =
      localStorage.getItem(`${id}_sales_part3_typed`) || ''

    form.sales_number = localStorage.getItem(`sales_number`) || ''
    form.sales_name = localStorage.getItem(`sales_name`) || ''
    form.sales_phone = localStorage.getItem(`sales_phone`) || ''
  },
}
